<template>
  <div>
    <agent-link v-if="!logined && isFrom3M"></agent-link>
    <router-view v-else-if="!logined && !isFrom3M"></router-view>
    <div class="agent-container" v-else-if="logined">
      <div class="agent-nav">
        <el-scrollbar style="height: 100%">
          <div class="base-info">
            <div>
              <img src="./assets/img/head.png" alt="" />
            </div>
            <span>
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ showLoginName
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="modify">{{
                    $t("app.label.modifyPassword")
                  }}</el-dropdown-item>
                  <el-dropdown-item command="modifyTransaction">{{
                    $t("app.label.modifyTransactionPassword")
                  }}</el-dropdown-item>
                  <el-dropdown-item command="logout">{{
                    $t("app.label.logoutAccount")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <div style="color: #fff">
              <span
                >{{ $t("app.label.balance")
                }}{{ $t("public.label.colon") }}</span
              >
              <span>{{ currentBalance | floatFormatCurrencyUnit }}</span>
            </div>
            <SelectCurrencyVue
              label="钱包"
              v-model="currencyValue"
              immediate
              global
              @change="handleCurrencyChange"
              size="mini"
              style="margin-top: 10px"
            />
          </div>
          <myNavVue></myNavVue>
        </el-scrollbar>
      </div>

      <div class="agent-content">
        <div class="header-container">
          <ul
            class="withdraw"
            :class="{ no_money: currentBalance <= 0 }"
            style="line-height: 30px"
          >
            <!-- 取款 -->
            <li
              v-show="
                (!userInfo.webResource ||
                  userInfo.webResource.indexOf(400002) !== -1)
              "
              @click="
                myClick('withdraw', $t('public.label.transaction.withdraw'))
              "
            >
              <img v-if="currentBalance > 0" src="./assets/img/withdraw.png" />
              <img
                v-if="currentBalance <= 0"
                src="./assets/img/withdraw2.png"
              />
              {{ $t("public.label.transaction.withdraw") }}
            </li>

            <!-- 转游戏 -->
            <li
              v-show="
                (!userInfo.webResource ||
                  userInfo.webResource.indexOf(400004) !== -1)
              "
              @click="
                myClick(
                  'turnGame',
                  $t('public.label.transaction.commissionGame')
                )
              "
            >
              <img v-if="currentBalance > 0" src="./assets/img/game.png" />
              <img v-if="currentBalance <= 0" src="./assets/img/game2.png" />
              {{ $t("public.label.transaction.commissionGame") }}
            </li>
            <!-- 给下线充值 -->
            <li
              v-if="
                isMerChant &&
                (!userInfo.webResource ||
                  userInfo.webResource.indexOf(400005) !== -1)
              "
              class="isMerChant"
              @click="
                myClick(
                  'turnLine',
                  $t('public.label.transaction.gameCurrencyPoints')
                )
              "
            >
              <img v-if="currentBalance >= 0" src="./assets/img/offline.png" />
              <img v-if="currentBalance < 0" src="./assets/img/offline2.png" />
              {{ $t("public.label.transaction.gameCurrencyPoints") }}
            </li>

            <!-- 转下线 -->
            <li
              v-else-if="
                loginInfo.showToDownline &&
                (!userInfo.webResource ||
                  userInfo.webResource.indexOf(400003) !== -1)
              "
              @click="
                myClick(
                  'turnLine',
                  $t('public.label.transaction.commissionDownline')
                )
              "
            >
              <img v-if="currentBalance > 0" src="./assets/img/offline.png" />
              <img v-if="currentBalance <= 0" src="./assets/img/offline2.png" />
              {{ $t("public.label.transaction.commissionDownline") }}
            </li>
            <!--这里是存款功能，暂时没有-->
            <!-- 交易记录 -->
            <li
              class="transaction-record"
              v-if="
                !userInfo.webResource ||
                userInfo.webResource.indexOf(200004) !== -1
              "
              @click="
                myClick('transactionRecord', $t('app.label.transactionRecord'))
              "
            >
              <span>{{ $t("app.label.transactionRecord") }}</span>
            </li>
            <!-- 操作手册下载 -->
            <li class="transaction-record">
              <span
                ><a href="manual.docx" download="操作手册">{{
                  $t("app.label.download")
                }}</a></span
              >
            </li>
          </ul>
          <div class="broadcast" v-if="announcement">
            <span></span>
            <marquee
              onMouseOut="this.start()"
              onMouseOver="this.stop()"
              behavior="scroll"
              direction="left"
              scrollamount="3"
            >
              <div v-html="announcement"></div>
            </marquee>
          </div>
          <ul class="customer-service">
            <li></li>
            <li>
              <span @click.stop="messageDialogVisible = true">
                <span class="road_box">
                  <i class="icon icon-message"></i>
                  <span
                    class="road"
                    v-if="unreadMessage.length > 0"
                  ></span>
                </span>
                <span>{{ $t("app.label.message") }}</span>
              </span>
            </li>
            <li>
              <span @click.stop="serviceDialogVisible = true">
                <span><i class="icon icon-customer-service"></i></span>
                <span>{{ $t("app.label.customerService") }}</span>
              </span>
            </li>
          </ul>
        </div>
        <div class="historical-record-container">
          <div class="prev-button" @click="prev">
            <span></span>
            <span></span>
          </div>
          <div class="historical-record">
            <ul>
              <li
                v-for="(item, index) in routerList"
                :key="index"
                :class="{ active: item.active }"
                @click="routerClick(item.name)"
              >
                {{ item.title }}
                <span
                  @click.stop="refresh(item.name, item.active)"
                  class="el-icon-refresh"
                ></span>
                <span
                  v-if="item.title !== $t('myNav.label.home')"
                  @click.stop="routerCancel(item.name)"
                  >×</span
                >
              </li>
            </ul>
          </div>
          <div class="next-button" @click="next">
            <span></span>
            <span></span>
          </div>
          <div class="logout-button" @click="logout">
            {{ $t("app.label.logout") }}
          </div>
        </div>
        <el-scrollbar style="height: 100%">
          <div class="agent-main">
            <keep-alive>
              <router-view></router-view>
            </keep-alive>
          </div>
        </el-scrollbar>
      </div>

      <!-- 消息列表 -->
      <MessageListDialog
        v-if="messageDialogVisible && logined"
        v-model="messageDialogVisible"
        @modifyMsgDetail="modifyMsgDetail"
      />
      <!-- 消息详情 -->
      <MessageDetailDialog
        v-if="modifyMessageDialogVisible"
        :formMsgDetail="formMsgDetail"
        v-model="modifyMessageDialogVisible"
      />
      <!-- 客服 -->
      <ServiceDialogVue
        v-if="serviceDialogVisible"
        v-model="serviceDialogVisible"
      />
    </div>
    <AccessModal ref="accessModal" />
    <!-- 余额锁定提示框 -->
    <BalanceLockModal ref="balanceLockModal" />
    <DialogLetter v-if="shownoread && logined" @open="messageDialogVisible = true" />
  </div>
</template>

<script>
import myNav from "./components/public/myNav.vue";
import AccessModal from "./components/public/AccessModal.vue";
import * as api from "./api/api.js";
import * as myutil from "./api/myutil.js";
import * as md5 from "./api/md5";
import BalanceLockModal from "@/components/public/BalanceLockModal";
import AgentLink from "@/components/agent3m/agentLink";
import AccountSwitch from "@/components/switch/accountSwitch";
import DialogLetter from "@/components/dialog";
import SelectCurrencyVue from "./components/select/SelectCurrency.vue";
import { mapState } from "vuex";
import ServiceDialogVue from "./components/dialog/serviceDialog.vue";
import MessageDetailDialog from "./components/dialog/messageDetailDialog.vue";
import MessageListDialog from "./components/dialog/messageListDialog.vue";

export default {
  name: "App",
  components: {
    AccountSwitch,
    AgentLink,
    BalanceLockModal,
    myNavVue: myNav,
    AccessModal,
    DialogLetter,
    SelectCurrencyVue,
    ServiceDialogVue,
    MessageDetailDialog,
    MessageListDialog,
  },
  data() {
    return {
      currencyValue: "",
      messageDialogVisible: false,
      modifyMessageDialogVisible: false,
      serviceDialogVisible: false,
      formMsgDetail: {
        title: "",
        content: "",
      },
      rules: {
        contactNumber: [
          {
            trigger: "change",
            min: 11,
            max: 12,
            message: this.$t("app.msg.error.contactNumber"),
          },
        ],
      },
      maxNum: 1,
      isFrom3M: false,
      shownoread: false,
    };
  },
  watch: {
    isAccessModal(value) {
      this.showAccessModal();
    },
    userInfo() {
      this.showBalanceLockModal();
    },
    $route: {
      // 未登录状态，判断是否VIP站；
      handler(val, oldVal) {
        let loginInfoFromStorage = window.sessionStorage.getItem("loginInfo");
        if (loginInfoFromStorage) {
          this.shownoread = true;
        } else {
          this.shownoread = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState([
      "currentWallet",
      "walletList",
      "selestCurrency",
      "hasTransactionPwd",
      "userInfo",
      "lockedDialogVisible",
      "verifyCode",
      "announcement",
      "isAccessModal",
      "logined",
      "unreadMessage"
    ]),
    routerList() {
      const list = this.$store.state.routerList || [];
      return list.filter((v) => v.name);
    },
    loginInfo() {
      if (this.logined && this.isFrom3M) {
        let loginInfoFromStorage = window.sessionStorage.getItem("loginInfo");
        if (loginInfoFromStorage) {
          try {
            api.loginInfo = JSON.parse(loginInfoFromStorage);
            this.isFrom3M = false;
          } catch (e) {}
        }
      }
      return api.loginInfo;
    },
    showLoginName() {
      const loginName =
        api.productInfo.prefixLoginName === "0"
          ? this.userInfo.loginName.substring(1, this.userInfo.loginName.length)
          : this.userInfo.loginName;
      return loginName;
    },
    currency() {
      return Number(this.$store.state.currency);
    },
    isMerChant() {
      return this.loginInfo.isMerChant && this.userInfo.isBiMerchant === 1;
    },
    isLocked() {
      return ["1", "2"].includes(this.userInfo.lockBalanceStatus);
    },
    currentBalance() {
      return this.currentWallet.amount || 0;
    },
  },
  mounted() {
    try {
      this.loginData = JSON.parse(window.sessionStorage.getItem("3m"));
      this.isFrom3M = this.loginData;
    } catch (e) {}

    if (
      !this.$router.history.pending ||
      this.$router.history.pending.name === "login"
    ) {
      this.routerList[0].active = true;
    } else {
      this.routerList.forEach((item) => {
        item.active = false;
        if (item.name === this.$router.history.pending.name) {
          item.active = true;
        }
        if (item.name === "turnLine") {
          item.title = this.isMerChant
            ? this.$t("public.label.transaction.gameCurrencyPoints")
            : this.$t("public.label.transaction.commissionDownline");
        }
      });
    }
    myutil.routerPosition();
    this.showAccessModal();
    this.showBalanceLockModal();
    window.addEventListener("beforeunload",this.refreshWallet());
  },
  destroyed() {
    window.removeEventListener("beforeunload",this.refreshWallet());
  },
  methods: {
     refreshWallet() {
      if(this.logined){
        myutil.getWallet();
      }
    },
    handleCurrencyChange() {},
    handleCommand(command) {
      if (command === "logout") {
        this.logout();
      } else if (command === "modify") {
        this.myClick("modifyPassword", this.$t("app.label.modifyPassword"));
      } else if (command === "modifyTransaction") {
        this.myClick(
          "modifyTransactionPassword",
          this.$t("app.label.modifyTransactionPassword")
        );
      }
    },

    logout() {
      this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, .8)",
      });
      api.logout(); //退出登录后不需要在这里写刷新验证吗的请求，在login组件中有
      this.$router.push({ name: "/" });
    },
    myClick(path, name) {
      this.showBalanceLockModal();
      if (
        this.isMerChant &&
        this.currentBalance <= 0 &&
        (path === "turnGame" || path === "withdraw")
      ) {
        this.$message.error("额度不足");
        return;
      }
      if (
        !this.isMerChant &&
        this.currentBalance <= 0 &&
        (path === "turnGame" || path === "turnLine" || path === "withdraw")
      ) {
        this.$message.error("额度不足");
        return;
      }
      let query = {};
      let isNeedTransactionPwd = api.productInfo.is_need_transactionPwd;
      if (
        isNeedTransactionPwd === "1" &&
        !this.hasTransactionPwd &&
        (path === "turnGame" || path === "turnLine" || path === "withdraw")
      ) {
        query.name = name;
        query.path = path;
        path = "modifyTransactionPassword";
        name = this.$t("app.label.modifyTransactionPassword");
      }
      let isAdd = true;
      this.routerList.forEach((item) => {
        item.active = false;
        if (item.title === name) {
          isAdd = false;
          item.active = true;
        }
      });
      if (isAdd) {
        let obj = {
          title: name,
          name: path,
          active: true,
        };
        this.routerList.push(obj);
        this.$store.commit("routerList", this.routerList);
      }
      myutil.routerPosition();
      if (query.name) {
        this.$router.push({ name: path, query: query });
      } else {
        this.$router.push({ name: path });
      }
    },
    routerClick(name) {
      this.$router.push({ name: name });
      this.routerList.forEach(function (item) {
        item.active = false;
        if (item.name === name) {
          item.active = true;
        }
      });
      myutil.routerPosition();
    },
    routerCancel(name) {
      this.routerList.forEach((item, index) => {
        if (item.name === name) {
          this.routerList.splice(index, 1);
          if (item.active) {
            this.routerList[index - 1].active = true;
            this.$router.push({ name: this.routerList[index - 1].name });
          }
          this.$store.commit("routerList", this.routerList);
        }
      });
      myutil.routerPosition();
    },

    modifyMsgDetail(item) {
      this.formMsgDetail = { ...item };
      this.modifyMessageDialogVisible = true;
    },

    prev() {
      for (let i = 0; i < this.routerList.length; i++) {
        if (this.routerList[i].active) {
          if (i !== 0) {
            this.routerList[i].active = false;
            this.routerList[i - 1].active = true;
            this.$router.push({ name: this.routerList[i - 1].name });
          }
          myutil.routerPosition();
          return;
        }
      }
    },
    next() {
      for (let i = 0; i < this.routerList.length; i++) {
        if (this.routerList[i].active) {
          if (i < this.routerList.length - 1) {
            this.routerList[i].active = false;
            this.routerList[i + 1].active = true;
            this.$router.push({ name: this.routerList[i + 1].name });
          }
          myutil.routerPosition();
          return;
        }
      }
    },
    refresh(name, active) {
      if (active) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name });
        this.routerList.forEach((item) => {
          item.active = false;
          if (item.name === name) {
            item.active = true;
          }
        });
        myutil.routerPosition();
      }
    },
    showAccessModal() {
      if (this.isAccessModal) {
        if (this.logined) {
          this.logout();
        } else {
          this.$refs.accessModal && this.$refs.accessModal.show();
        }
      }
    },
    showBalanceLockModal() {
      //余额锁定
      if (this.logined) {
        if (this.isLocked)
          this.$refs.balanceLockModal && this.$refs.balanceLockModal.show();
      } else {
        this.$refs.balanceLockModal.close();
      }
    },
  },
};
</script>

<style>
body {
  display: flex;
  flex-direction: column;
}

body > div:first-child {
  height: 100%;
}

body > div,
body > div > div {
  flex-grow: 1;
  display: flex;
}

.agent-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100vw;
}

.agent-nav {
  flex-grow: 0;
  width: 220px;
  background: cornflowerblue;
}

.agent-content {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 220px);
}

.agent-main {
  margin: 0 20px 0;
  padding: 20px 0;
}

.el-submenu .el-menu-item {
  padding: 0 30px !important;
}

.historical-record-container + .el-scrollbar {
  height: calc(100% - 113px) !important;
}

.base-info {
  font-size: 16px;
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, #3e78e2, #5587e2);
}

.base-info > div:first-child {
  width: 73px;
  height: 73px;
  border-radius: 50%;
  overflow: hidden;
}

.base-info > div:first-child > img {
  width: 73px;
  height: 73px;
}

.base-info > span {
  margin: 10px 0;
  color: #fff;
}

.base-info > span .el-dropdown {
  color: #fff;
  cursor: pointer;
}

.base-info > div:last-child .el-button {
  height: 26px;
  line-height: 26px;
  padding: 0 5px;
  width: 74px;
}

.base-info .el-button a:hover {
  border-color: #409eff;
  color: #409eff;
  text-decoration: none;
}

.base-info .el-button a {
  color: #fff;
}

.base-info .el-button {
  color: #dcdfe6;
  background-color: transparent;
}

.base-info .el-button:hover {
  background-color: transparent;
}

.base-info .el-button + .el-button {
  margin-left: 13px;
}

.header-container {
  height: 60px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  justify-content: space-between;
  box-shadow: 0 5px 10px 0 #f1f5fd;
}

.header-container > ul.withdraw {
  display: flex;
  flex-direction: row;
}

.header-container li {
  line-height: 39px;
}

/*.header-container ul.withdraw>li:not(:last-child),*/
.header-container ul.withdraw > li:not(.transaction-record) {
  border-radius: 3px;
  background: #fff;
  width: auto;
  height: 39px;
  line-height: 39px;
  text-align: center;
  box-shadow: 0 5px 10px 2px #e8ecf6;
  margin-right: 20px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.header-container ul.withdraw > li.transaction-record > span {
  cursor: pointer;
  border-bottom: 1px solid #3975e2;
  font-size: 12px;
  color: #3975e2;
  margin-right: 20px;
}

.header-container ul.withdraw > li a:hover {
  color: #135cdf;
}

.header-container .customer-service {
  font-size: 12px;
  color: #666;
}

.header-container .customer-service > li {
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}

.header-container .customer-service > li > span {
  display: flex;
  flex-direction: column;
}

.header-container .customer-service > li > span:first-child {
  line-height: 22px;
  text-align: center;
}

.header-container .customer-service > li > span:nth-child(2) {
  line-height: normal;
}

.header-container .customer-service > li:first-child > span {
  width: 60px;
}

.header-container .customer-service > li:first-child > span > span:first-child {
  height: 0;
}

.header-container
  .customer-service
  > li:first-child
  > span
  > span:nth-child(2)
  > .el-select {
  display: block;
  height: 44px;
}

.header-container
  .customer-service
  > li:first-child
  > span
  > span:nth-child(2)
  > .el-select
  > .el-input {
  position: absolute;
  bottom: 0;
}

.header-container .customer-service > li:nth-child(2) {
  padding: 0 30px 0 15px;
}
.road_box {
  position: relative;
}
.road {
  width: 10px;
  height: 10px;
  background: #ff0000;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -3px;
}
.header-container ul.withdraw > li > img {
  display: inline-block;
  width: 20px;
  height: 20px;
  padding-right: 5px;
  padding-bottom: 3px;
}

.header-container > div:first-child {
  color: #3975e2;
  line-height: 39px;
}

.header-container > div:first-child > span:nth-child(2) {
  font-size: 22px;
}

.customer-service > li:first-child .el-input__inner {
  border: none;
  width: 60px;
  padding: 0;
  font-size: 12px; /*16*/
  line-height: 39px;
  color: #666;
  height: 14px;
  text-align: center;
}

.historical-record {
  margin-top: 10px;
  /*padding: 0 140px 0 70px;*/
  width: calc(100% - 150px);
  /*border-bottom: 3px solid #3975E2;*/
  height: 40px;
  overflow: hidden;
}

.historical-record-container {
  margin-top: 9px;
  border-top: 1px solid #e8e8e8;
  height: 44px;
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid #3975e2;
  align-items: flex-end;
  width: 100%;
}

.historical-record > ul {
  height: 40px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 14px;
  /*overflow: hidden;*/
  width: 100%;
}

.historical-record > ul > li {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  background: #fff;
  display: inline-block;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-right: none;
  box-sizing: border-box;
  color: #aaa;
  cursor: pointer;
  flex-shrink: 0;
}

.historical-record > ul > li:last-child {
  border-right: 1px solid #e8e8e8;
}

.historical-record > ul > li:hover {
  background: #f2f2f2;
}

.historical-record > ul > li.active {
  background: #3975e2;
  color: #fff;
  border-color: #3975e2;
}

.historical-record > ul > li > span {
  padding-left: 5px;
}

.customer-service > li:first-child .el-input__suffix-inner .el-select__caret {
  display: none;
  /*color: #000;*/
}

.el-input--small {
  width: 242px;
}

.no_money > li:nth-child(1),
.no_money > li:nth-child(2),
.no_money > li:nth-child(3) {
  cursor: not-allowed !important;
}

.no_money > li.isMerChant {
  cursor: pointer !important;
}

.el-dialog__body {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
}

.cs-dialog .el-dialog__body,
.msg-detail .el-dialog__body {
  align-items: center;
}

.message-table {
  width: 100%;
}

.message-table .message-table-title {
  padding: 0 10px 10px 40px;
  border-bottom: 1px solid #e8e8e8;
  font-weight: bold;
  display: flex;
}

.message-table .table-list {
  font-size: 14px;
  padding: 15px 10px 15px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.message-table li > span:first-child {
  flex: 2;
}

.message-table li > span:nth-child(2),
.message-table li > span:nth-child(3) {
  flex: 1;
}

.message-table .modify-msg {
  cursor: pointer;
}

.noData {
  text-align: center;
  margin: 50px 0;
}

.noData > div {
  font-size: 20px;
  color: #aaa;
}

.noData img {
  height: 70px;
  width: 70px;
  margin-bottom: 20px;
}

marquee span {
  display: inline-block;
  margin-right: 40px;
  opacity: 0.8;
  font-size: 13px;
  color: #3975e2;
  line-height: 30px;
}

marquee {
  width: calc(100% - 30px);
}

.broadcast {
  /*height: 30px;*/
  /*background-color:  #EDF3FD;*/
  padding: 0 20px;
  width: 300px;
  line-height: 40px;
  display: flex;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 1600px) {
  .broadcast {
    width: 206px;
  }
}

.broadcast > span {
  display: inline-block;
  width: 20px;
  height: 16px;
  background: url("./assets/img/broadcast.png") no-repeat;
  background-size: 20px 16px;
}

.color-g {
  color: #78c815;
}

.pagination_footer {
  margin: 0;
  padding: 15px 0 !important;
  border-bottom: none !important;
}

.msg-detail input,
.msg-detail textarea {
  font-family: PingFangSC-Regular;
}

.msg-detail .resize-height {
  width: 75%;
}

.msg-detail .resize-height .el-input {
  width: 100%;
}

.message-table > .resize-height,
.msg-detail .resize-height {
  height: 492px;
}

.prev-button {
  width: 40px;
  height: 40px;
  background: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  padding-right: 10px;
}

.next-button {
  width: 40px;
  height: 40px;
  background: #fff;
  cursor: pointer;
  border-left: 1px solid #e8e8e8;
  text-align: center;
  line-height: 40px;
  padding-left: 10px;
}

.logout-button {
  width: 70px;
  height: 40px;
  background: #fff;
  cursor: pointer;
  border-left: 1px solid #e8e8e8;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
}

.logout-button:hover,
.prev-button:hover,
.next-button:hover {
  background: #f2f2f2;
}

.prev-button > span {
  display: inline-block;
  border: 6px solid transparent;
  border-right-color: #999;
}

.next-button > span {
  display: inline-block;
  border: 6px solid transparent;
  border-left-color: #999;
}

.prev-button > span + span {
  margin-left: -11px;
}

.next-button > span:first-child {
  margin-right: -11px;
}

.captcha-img {
  width: 100px;
  margin-left: 5px;
}

@media screen and (max-width: 1366px) {
  .message-table > .resize-height,
  .msg-detail .resize-height {
    height: 300px;
  }

  .msg-detail textarea {
    height: calc(100% - 92px) !important;
  }
}
/* 打包测试 */
.mint-indicator-mask-test {
}
</style>
